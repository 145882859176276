<template>
  <div class="VideoPopup">
    <div class="VideoPopup__container">
      <div class="VideoPopup__header">
        <button class="close-button" @click.prevent="close">
          <img src="@/assets/svg/close-icon.svg" alt="close" />
        </button>
      </div>
      <div class="VideoPopup__content">
        <video controls>
          <source :src="videoSource" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoPopup',

  props: {
    videoSource: {
      type: String,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/stylesheets/components/common/VideoPopup.scss';
</style>
